import React, { useEffect, useState } from 'react'
import {formatDate} from '../customFunctions/customFunctions'
import {PageTitle} from '../../../_metronic/layout/core'
import {useAuth} from '../auth'
import {useParams} from 'react-router-dom'
import axios from 'axios'


interface Card {
    id: string;
    cardholder: string;
}
interface Data{
    id: string;
    card: Card;
    amount: string;
    created_at: string;
    submerchant: Submerchant;
    transaction: Transaction;
}


interface Transaction {
    id : string;
    numbero: string;
    amount: string;
    created_at: string;
    submerchant: Submerchant;
}

interface Submerchant {
    name: string
    dba_name: string
    mcc: string
    total_sum: string
    average: string
    descriptionOfGoods: string
    total_count: string
    monthly_processing_limit: string
    refund: string
    chargeback: string
    location: string
    street: string
    city: string
    province: string
    zip: string
    phone: string
    email: string
    pciCompliance: string
    financialReviewNotes: string
    businessReviewNotes: string
    riskManagement: string
    reviewConclusions: string
    reviewPreparedBy: string
    riskReviewCompletedBy: string
    // reviewApprovedBy: string;
    // transactions:Transaction[];
    }


    const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

    export default function ViewReport() {
      const { trans } = useParams();
      const { logout } = useAuth();
      const user = useAuth().auth?.user;
    
      const [data, setData] = useState<Data[]>([]); // Ensure correct typing
      const [error, setError] = useState<string | null>(null);
    
      useEffect(() => {
        const fetchData = async () => {
          try {
            const result = await axios.get<Data[]>(`${API_URL}/api/view/report/${trans}`);
        
            if (result.data && result.data.length > 0) {
              setData(result.data);
            } else {
              setError('No transactions found for the given report.');
            }
          } catch (err) {
            console.error(err);
            setError('An error occurred while fetching the report.');
          }
        };
    
        const interceptor = axios.interceptors.response.use(
          response => response,
          error => {
            if (error.response && error.response.status === 401) {
              logout();
            }
            return Promise.reject(error);
          }
        );
    
        fetchData();
    
        return () => {
          axios.interceptors.response.eject(interceptor);
        };
      }, [trans, logout]);

    //   console.log('test', data[0].transaction)
    
      const transactions = data[0]?.transaction;

  return (
    <>
    <PageTitle breadcrumbs={[]}>Large Submerchant</PageTitle>
    <div className='flex space-x-15'>
        <div className='card '>
            <div className='fw-bolder text-center p-3'>Submerchant Information</div>
            {data.length > 0 ?
             <div className='card-body'>
            <div className='row p-2'>
            <span className='col-2 fw-bold text-dark border border-dark p-3 border-right-0'>Submerchant Location</span>
            <span className='col-10 fw-bold text-dark border border-dark p-3'>{transactions?.submerchant.location}</span>
            </div>
            <div className='row p-2'>
            <span className='col-2 fw-bold text-dark border border-dark p-3 border-right-0'>Submerchant Adress</span>
            <span className='col-10 fw-bold text-dark border border-dark p-3'>{transactions?.submerchant.street}</span>
            </div>
            <div className='row p-2'>
            <span className='col-2 fw-bold text-dark border border-dark p-3 border-right-0'>Submerchant City</span>
            <span className='col-10 fw-bold text-dark border border-dark p-3'>{transactions?.submerchant.city}</span>
            </div>
            <div className='row p-2'>
            <span className='col-3 fw-bold text-dark border border-dark p-3 border-right-0'>Province</span>
            <span className='col-3 fw-bold text-dark border border-dark p-3 border-right-0'>{transactions?.submerchant.province}</span>
            <span className='col-3 fw-bold text-dark border border-dark p-3 border-right-0'>Postal Code</span>
            <span className='col-3 fw-bold text-dark border border-dark p-3'>{transactions?.submerchant.zip}</span>
            </div>
            <div className='row p-2'>
            <span className='col-12 fw-bold text-dark  text-center border border-dark p-3'>Transactions
         </span>
            </div>
            {data.map((transaction, index) => {
                return <div className='row p-2' key={index}>
                            <div className='row p-2'>
                            <span className='col-12 fw-bold text-center text-dark border border-dark p-3'>Transaction Information</span>
                            </div>
                            <div className='row p-2'>
                            <span className='col-3 fw-bold text-dark border border-dark p-3 border-right-0'>Date of Transaction</span>
                            <span className='col-3 fw-bold text-dark border border-dark p-3 border-right-0'>{formatDate(transaction.created_at)}</span>
                            <span className='col-3 fw-bold text-dark border border-dark p-3 border-right-0'>Date Of Posting</span>
                            <span className='col-3 fw-bold text-dark border border-dark p-3'>{formatDate(new Date().toISOString())}</span>
                           
                            </div>
                            <div className='row p-2'>
                            <span className='col-2 fw-bold text-dark border border-dark p-3 border-right-0'>Amount</span>
                            <span className='col-5 fw-bold text-dark border border-dark p-3 border-right-0'>{data[index].transaction.amount}</span>
                            <span className='col-3 fw-bold text-dark border border-dark p-3 border-right-0'>Transaction Currency</span>
                            <span className='col-2 fw-bold text-dark border border-dark p-3 '>CAD</span>
                          
                          
                            </div>
                            </div>

            }
            )}

      
            <div className='row p-2'>
            <span className='col-12 fw-bold text-center text-dark border border-dark p-3'>Completed by</span>
            </div>
            <div className='row p-2'>
            <span className='col-3 fw-bold text-dark border border-dark p-3 border-right-0'>Review Prepared By:</span>
            <span className='col-3 fw-bold text-dark border border-dark p-3 border-right-0'>{user?.name}</span>
            <span className='col-3 fw-bold text-dark border border-dark p-3 border-right-0'>Date:</span>
            <span className='col-3 fw-bold text-dark border border-dark p-3'>{formatDate(new Date().toISOString())}</span>
            </div>
           
            </div> 
            : <p>Not found</p>}

          

        </div>
    </div>
    </>
  )
}
