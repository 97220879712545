import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../_metronic/helpers'
import { useEffect,useState } from 'react'
import ReactPaginate from 'react-paginate'


import { Drawer,Box } from '@material-ui/core'

import '../../../paginate.css'
// import { fetchCsrfToken } from './getToken';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight, faAnglesRight, faCoffee } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import { getBaseUrl,checkPermission } from '../customFunctions/customFunctions'
import Swal from 'sweetalert2';
import {useAuth} from '../auth/core/Auth'
import {Button} from '@material-ui/core'
import {Typography} from '@material-ui/core'
import { toast,ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// const DailyReportPage: React.FC = () => (


// )
function formatMoney(amount:any){
    amount = parseFloat(amount)
    const formattedAmount = amount.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });

  return formattedAmount
}
interface Submerchant {
    id: string;
    name: string;
    email:string;
    phone: string;
    // Add any other fields based on your requirements
}
interface TransactionType {
    id: string;
    name: string;
    // Add any other fields based on your requirements
}

  interface Checklist {
    id: string;
    name: string;
    status: boolean;
  }
interface Transaction {
    number: string;
    amount: number;
    transaction_type_id: number;
    submerchant_id: string;
    card_id: string;
    batch_id: string;
    batch_amount: number;
    submerchant:Submerchant;
    collection_amount: number;
    terminal_number: string;
    cardholder: string;
    status: boolean;
    manual_entry: boolean;
    swiped_mag_stripe: boolean;
    time_created: string;
    transaction_type:TransactionType;
    t_type:string;
    // Add any other fields based on your requirements
}
interface Image{
    id:string;
    image:string;
}
interface Incidents{
    id:string;
    status:number;
    image:Image[];
    transaction:Transaction;
    checklist:Checklist[];
    notes:string;
    issue:string;
    analyst:string;
}

const DailyReportPageWrapper: FC = () => {
    const {auth, setCurrentUser} = useAuth()
    const user = auth?.user;
    const permissions = auth?.permissions;
    
    console.log(permissions)
    const [transactions, setTransactions] =  useState<Incidents[]>([]);
    const [data,setData] = useState({current_page: 1, last_page: 1 });
    const [image, setImage] = useState("");
    const element = <FontAwesomeIcon icon={faAngleRight} size='2x' />
    const [currentTransaction, setCurrentTransactions]  =useState<Incidents | null>(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    
        const [currentChecklist,setCurrentChecklist] = useState({
            name :"",
            "status":false}
        )
    // const [issue, setIssue] = useState('');
    function handleSelect(transaction:any){
        setImage("")
        setCurrentTransactions((prevTransaction) =>
        prevTransaction === transaction ? { ...prevTransaction } : transaction
      );
      setIsDrawerOpen(true);

    }
    const handleViewImage = async (image:string)=>{
      const base = getBaseUrl();
      console.log(image)
  
      try {
        const response = await axios.post(`${base}api/get-image`, { image });
      
        if (response.data) {
          const url = response.data;
          window.open(url, '_blank');
        } else {
          console.error('Failed to fetch Image');
        }
      } catch (error) {
        console.error('An error occurred during the fetch', error);
      }
        

  }
    function changeNotes(e: React.ChangeEvent<HTMLTextAreaElement>) {
        if (currentTransaction) {
          setCurrentTransactions((prevCurrentTransaction) => {
            if (prevCurrentTransaction) {
              return {
                ...prevCurrentTransaction,
                notes: e.target.value,
              };
            }
            return null; // Return null if prevCurrentTransaction is null
          });
        }
      }
    function imageSelection(e:any) {
    //     var myCurrent = currentTransaction;
    //     if(myCurrent){
    //     myCurrent.image = URL.createObjectURL(e.target.files[0]);
    // }
    //     setImage(URL.createObjectURL(e.target.files[0]));
    //     handleSelect(myCurrent);
    }
    // const handleCheck = (transaction: Transaction, checklistId: string) => {
    //     setTransactions((prevTransactions) =>
    //       prevTransactions.map((tran) => {
    //         if (tran.number === transaction.number) {
    //           return {
    //             ...tran,
    //             checklist: tran.checklist.map((myc) =>
    //               myc.id === checklistId ? { ...myc, enabled: !myc.enabled } : myc
    //             ),
    //           };
    //         }
    //         return tran;
    //       })
    //     )
    //   };
    const handleSubmit = async (e:any)=>{
      const base = getBaseUrl();
        console.log(currentTransaction?.id);
;        const inputElement = document.getElementById('images') as HTMLInputElement;
          const formData = new FormData();
          formData.append("id",currentTransaction?.id as string)
          formData.append("notes",currentTransaction?.notes as string)
          if (inputElement.files) {
            for (let i = 0; i < inputElement.files.length; i++) {
              formData.append('images[]', inputElement.files[i]);
            }
          }
        
        
       
        try {
         
          
          axios
          .post(base+"api/daily_report", formData, 
          {
            headers: {
              "Content-Type": "multipart/form-data"
            },
          })
          .then((response) => {
        // handle the response
            // console.log(response.data);
          })
          
          Swal.fire({
              icon: 'success',
              title: 'Success!',
              text: 'Transaction approved succesfully',
            }).then(() => {
              // Reload the page after the SweetAlert is closed

              window.location.reload();
            });
          } catch (error) {
            // Handle error
            console.error('Error creating resolution:', error);
          }
    }
    const closeDrawer = () => {
      setIsDrawerOpen(false);
    };
    const suspendBatch = async (batch:any)=>{
        const base = getBaseUrl();
    
        try {
          const response = await axios(`${base}api/suspend_batch/${batch}`);
         
          if (response.status === 200) {
            if(response.data.status === 401){
              toast.error("You do not have permission to suspend batches");
            }
            else{
            Swal.fire({
              icon: 'success',
              title: 'Success!',
              text: 'Batch suspended succesfully',
            }).then(() => {
              // Reload the page after the SweetAlert is closed
              window.location.reload();
            });
          }
          } else{
           
            console.error('Failed to suspend batch');
          }
        } catch (error) {
          console.error('An error occurred during the fetch', error);
        }
          
  
    }
    const suspendAccount = async (submerchant:any)=>{
      const base = getBaseUrl();
  
      try {
        const response = await axios(`${base}api/suspend_account/${submerchant}`);
        if (response.status === 200) {
          if(response.data.status === 401){
            toast.error("You do not have permission to suspend accounts");
          }
          else{
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Batch suspended succesfully',
          }).then(() => {
            // Reload the page after the SweetAlert is closed
            window.location.reload();
          });
        }
        } else {
          console.error('Failed to suspend Submerchant');
        }
      } catch (error) {
        console.error('An error occurred during the fetch', error);
      }
        

  }
    const handleChecks = () => {

            return currentTransaction?.checklist.every(item => item.status);
          };


    function handleCheck(transaction:any,checklisId:any){
        console.log(transaction.notes)
        var myTransaction = transactions;
        var specific
        myTransaction.map((tran => {
                if(tran.id==transaction.id){
                    tran.notes = transaction.notes
                    tran.checklist.map(myc=>{
                        if(myc.id === checklisId){
                                myc.status = !myc.status

                                handleSelect(tran);
                                setTransactions(myTransaction)


                        }
                    })
                }

        }

        ))


    }

        // Here we use item offsets; we could also use page offsets
        // following the API or data you're working with.
        const base = getBaseUrl()
        const [itemOffset, setItemOffset] = useState(0);
        // const [CsrfToken,setCsrfToken] = useState("");
        const itemsPerPage = 12;
        // Simulate fetching items from another resources.
        // (This could be items from props; or items loaded in a local state
        // from an API endpoint with useEffect and useState)
        const endOffset = itemOffset + itemsPerPage;
        // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        // const currentItems = transactions.slice(itemOffset, endOffset);
        // const pageCount = Math.ceil(transactions.length / itemsPerPage);
        const { logout } = useAuth()
        // const csrf_token = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content'); 
       

        // Invoke when user click to request another page.
        const handlePageClick = (event:any) => {
          const newOffset = (event.selected * itemsPerPage) % transactions.length;
          console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
          );
          setItemOffset(newOffset);
        };
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios(base+'api/daily_report/'+user?.role_id+'?page='+data.current_page); // Adjust the URL accordingly
            if (response.data) {
              const data = response.data.data;
              // console.log(data)
              setTransactions(data);
              setData({current_page: response.data.current_page, last_page: response.data.last_page });

            } else {
              console.error('Failed to fetch data');
            }
          } catch (error) {
            console.error('An error occurred during the fetch', error);
          }
        };
        
        const interceptor = axios.interceptors.response.use(
          
          response => response,
          error => {
            if (error.response && error.response.status === 401) {
              logout();
            }
            return Promise.reject(error);
          }
        );
        fetchData()
        return () => {
          axios.interceptors.response.eject(interceptor);
         
        };
       

       
      }, [data.current_page,logout]);

  return (
    <>
      <PageTitle breadcrumbs={[]}>Daily Report</PageTitle>
      <div className='flex space-x-15'>
       <ToastContainer />


    <div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Incidents</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{new Date().toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})}</span>
        </h3>

      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive '>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>

                <th className='min-w-50px'>TID</th>
                <th className='min-w-50px'>Amount</th>
                <th className='min-w-200px'>Submerchant</th>
                <th className='min-w-100px'>Issue</th>
                <th className='min-w-50px'>Status</th>

              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {transactions.map((transaction, index) => (
            <tr key={index} onClick={()=>handleSelect(transaction)}>
              <td className='text-dark fw-bold text-hover-primary fs-6' >{transaction.transaction.number}</td>
              <td className='text-dark fw-bold text-hover-primary fs-6'>{ formatMoney(transaction.transaction.amount)}
            </td>
              <td className='text-dark fw-bold text-hover-primary fs-6'>
                {transaction.transaction.submerchant.name}
                <span className="text-muted fw-semibold text-muted d-block fs-7">{transaction.transaction.t_type}</span></td>
              <td className='text-dark fw-bold fs-6'>{transaction.issue}</td>
              <td className='text-dark fw-bold fs-6'>
                    {transaction.status == 1 ? (
                        <span className="badge badge-light-success">Reviewed</span>
                    ) : transaction.status == 0 ? (
                        <span className="badge badge-light-danger">Awaiting</span>
                    ) : (
                        <span className="badge badge-light-secondary">Unknown Status</span>
                    )}
</td>

            </tr>
          ))}

            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}

          <Box display="flex" justifyContent="center" alignItems="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setData({ ...data, current_page: data.current_page - 1 })}
              disabled={data.current_page === 1}
            >
              Previous
            </Button>

            <Typography variant="subtitle1" style={{ margin: '0 10px' }}>
              Page {data.current_page} of {data.last_page}
            </Typography>

            <Button
              variant="outlined"
              color="primary"
              onClick={() => setData({ ...data, current_page: data.current_page + 1 })}
              disabled={data.current_page === data.last_page}
            >
              Next
            </Button>
        </Box>
          {/* <ReactPaginate
        breakLabel="..."
        nextLabel={<FontAwesomeIcon icon={faAngleRight} size='xl' />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel={<FontAwesomeIcon icon={faAngleLeft} size='xl' />}
        renderOnZeroPageCount={null}
       className="react-paginate"
      /> */}

        </div>

        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>

    <Drawer anchor="right" open={isDrawerOpen} onClose={closeDrawer}>
    <Box sx={{ width: 550 }} role="presentation" className='p-5'>
        <div className='card-header cursor-pointer p-9 m-0'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Transaction Details</h3>
          </div>


        </div>
        {currentTransaction?.id!==""?
        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Ticket Number</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{currentTransaction?.transaction.number}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Company</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{currentTransaction?.transaction.submerchant.name}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              Phone
              <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title='Phone number must be active'
              ></i>
            </label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>{currentTransaction?.transaction.submerchant.phone}</span>

              <span className='badge badge-success'>Verified</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Address</label>

            <div className='col-lg-8'>
              <a href='#' className='fw-bold fs-6 text-dark text-hover-primary'>
                {currentTransaction?.transaction.submerchant.email}
              </a>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
             Terminal
              <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title='Country of origination'
              ></i>
            </label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{currentTransaction?.transaction.terminal_number}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
             Images
              <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title='Images'
              ></i>
            </label>

            <div className='col-lg-8'>

             {currentTransaction?.image.map((image, index) => (
                  <div key={index} className='row '>
                    <h6 className='col'> {`File ${index + 1}`} </h6> 
                    <span className="col text-primary cursor-pointer" onClick={()=>handleViewImage(image.image)}>View Image</span>
                  </div>
                ))}
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
             Notes
              <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title='notes'
              ></i>
            </label>

            <div className='col-lg-8'>
        <textarea
            className="form-control"
            name='notes'
            onChange={changeNotes}
            value={currentTransaction?.notes || ''}
            id="notes"
            ></textarea>
            </div>
          </div>

        <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Actions</label>

            <div className='col-lg-8'>

          {currentTransaction?.checklist.map((checklist,index)=>(

            <div className="form-check mb-5" key={index}>
            <input className="form-check-input" type="checkbox"
            // value={checklist.enabled}
            checked={checklist.status}
            onChange={() => handleCheck(currentTransaction, checklist.id)}
            />
            <label className="form-check-label" >
             {checklist.name}
            </label>
          </div>
          ))}

        <div className="form-check mb-5" >
            <input className="form-control" type="file"
            onChange={imageSelection}
            name='images[]'
            id="images"
            multiple
            />

          </div>
            </div>
        </div>
        <div className="form-check mb-5" >

        <div className="row mb-7" >

              <p> <strong>{currentTransaction?.analyst ? "Reviewed By "+currentTransaction?.analyst: "No Reviews"}</strong></p>


        </div>


        <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Decision</label>

            <div className='col-lg-8'>
            <button
            disabled = {!handleChecks()}
            style={{border:"1px solid"}}
            type="button"
            className="btn text-success text-hover-white btn-outline-success btn-sm m-1"
            onClick={handleSubmit}
            >Approve
            </button>
           

            <button type="button" className="btn btn-warning btn-sm m-1" onClick={()=>suspendBatch(currentTransaction?.transaction.batch_id)}>Suspend Batch</button>
            <button type="button" className="btn btn-danger btn-sm m-1" onClick={()=>suspendAccount(currentTransaction?.transaction.submerchant_id)}>Suspend Account</button>
            {/* <button type="button" className="btn btn-danger btn-sm m-1" onClick={()=>suspendAccount(currentTransaction?.id)}>Suspend Account</button> */}

            </div>


        </div>
        </div>



          <Link to={'submerchant/'+currentTransaction?.transaction.submerchant_id} className='fw-bold fs-6 text-success text-hover-primary'>
            View File {">>>"}
          </Link>


        </div> : <p className='text-muted'>No Transaction Selected</p>}
    </Box>
      </Drawer>
  
    </div>
    </>
  )
}

export default DailyReportPageWrapper
