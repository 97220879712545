/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {auth} = useAuth()
  const myRole = auth?.user.role_id

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/daily-report'
        icon='/media/icons/duotune/general/gen005.svg'
        title='Daily Report'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/ol-report'
        icon='/media/icons/duotune/general/gen014.svg'
        title='Out Of Limit Reports'
        fontIcon='bi-app-indicator'
      />
      {/* {checkPermission(permissions, 'Top submerchants') && ( */}
      <SidebarMenuItem
        to='/top-processing'
        icon='/media/icons/duotune/general/gen014.svg'
        title='Top Submerchants'
        fontIcon='bi-app-indicator'
      />
      {/* )} */}
      {/* {checkPermission(permissions, 'Underwrite') && ( */}
      <SidebarMenuItem
        to='/fintrac'
        icon='/media/icons/duotune/abstract/abs043.svg'
        title='Fintrac Report'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItemWithSub to='/display' title='Automation' hasBullet={true}>
        <SidebarMenuItem to='/display' title='View Rules' />
        <SidebarMenuItem to='/create' title='Create Rule' />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/watchlist'
        icon='/media/icons/duotune/general/gen013.svg'
        title='Watch List'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/create-ttype'
        icon='/media/icons/duotune/general/gen013.svg'
        title='Create Transaction Type'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/submerchant-controls'
        icon='/media/icons/duotune/general/gen013.svg'
        title='Submerchant Controls'
        fontIcon='bi-app-indicator'
      />

      {myRole === '1' && (
        <SidebarMenuItem
          to='/create-user'
          icon='/media/icons/duotune/general/gen013.svg'
          title='Create User'
          fontIcon='bi-app-indicator'
        />
      )}

      {myRole === '1' && (
        <SidebarMenuItem
          to='/roles'
          icon='/media/icons/duotune/communication/com009.svg'
          title='Roles'
          fontIcon='bi-app-indicator'
        />
      )}
    </>
  )
}

export {SidebarMenuMain}
