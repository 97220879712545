import * as React from 'react';
import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ReceiptIcon from '@mui/icons-material/Receipt';
// import { KTSVG } from '@metronic/helpers/components/KTSVG'


export const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    display: 'flex',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
      backgroundColor: '#1A2027',
    }),
}));

const DashboardSummaryCards: React.FC = () => {
    return (
        <>
        <div>
            {/* <TestComponent/> */}
            <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 3, md: 4 }}>
                <Grid size={3}>
                    <Item>
                        <StorefrontIcon style={{ fontSize: 40 }} />
                        {/* <Test/> */}
                        {/* <KTSVG path='/media/svg/general/refund.svg' className='svg-icon-2' /> */}
                        
                        <div style={{ display: 'inline-block' }}>
                            <span style={{ fontSize: 25, display: 'block' }}>1202</span>
                            <span style={{ fontSize: 13, display: 'block' }}>Active Merchants</span>
                        </div>
                    </Item>
                </Grid>
                <Grid size={3}>
                    <Item>
                        <ReceiptIcon style={{ fontSize: 40 }} />
                        <div style={{ display: 'inline-block' }}>
                            <span style={{ fontSize: 25, display: 'block' }}>350 Transactions</span>
                            <span style={{ fontSize: 13, display: 'block' }}>In the last 7 days</span>
                        </div>
                    </Item>
                </Grid>
                <Grid size={3}>
                    <Item>
                        {/* <SvgIcon component={MoneyRefundIcon} style={{ fontSize: 40, padding: 5 }} /> */}
                        <div style={{ display: 'inline-block' }}>
                            <span style={{ fontSize: 25, display: 'block' }}>9 Refunds</span>
                            <span style={{ fontSize: 13, display: 'block' }}>In the last 7 days</span>
                        </div>
                    </Item>
                </Grid>
                <Grid size={3}>
                    <Item>
                        {/* <SvgIcon component={ChargebackIcon} style={{ fontSize: 40, padding: 5 }} />  */}
                        <div style={{ display: 'inline-block' }}>
                            <span style={{ fontSize: 25, display: 'block' }}>15 Chargebacks</span>
                            <span style={{ fontSize: 13, display: 'block' }}>In the last 7 days</span>
                        </div>
                    </Item>
                </Grid>
            </Grid>
        </div>
        </>
    );
};

export default DashboardSummaryCards;
