import React, {useEffect, useState} from 'react'
import {getBaseUrl} from '../customFunctions/customFunctions'
import {Link} from 'react-router-dom'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Drawer,
} from '@material-ui/core'
import {useAuth} from '../auth'
import axios from 'axios'

interface Transaction {
  number: string
  amount: number
  transaction_type_id: number
  t_type: string
  submerchant_id: number
  card_id: string
  batch: string
  batch_amount: number
  submerchant: Submerchant
  collection_amount: number
  terminal_number: string
  cardholder: string
  status: boolean
  manual_entry: boolean
  swiped_mag_stripe: boolean
  created_at: string
  transaction_type: TransactionType
  // Add any other fields based on your requirements
}
interface TransactionType {
  name: string
  id: number
}
interface Submerchant {
  name: string
  id: number
  dba_name: string
  mcc: string
  transactions: Transaction[]
  phone: string
  address: string
  tax_id: string
}

// Rest of the code
const FintracReportTable = () => {
  const {logout} = useAuth()
  const [data, setData] = useState<Transaction[]>([])
  const [isDrawerOpen, setDrawerOpen] = useState(false)
  const [selectedTransaction, setSelectedTransaction] = useState<Transaction>()
  const base = getBaseUrl()

  // console.log(base)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios(base + 'api/fintrac-report') // Adjust the URL accordingly
        console.log(response)
        if (response.data) {
          const data = await response.data
          // console.log(data)
          setData(data)
        } else {
          console.error('Failed to fetch data')
        }
      } catch (error) {
        console.error('An error occurred during the fetch', error)
      }
    }
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          logout()
        }
        return Promise.reject(error)
      }
    )
    fetchData()
    return () => {
      axios.interceptors.response.eject(interceptor)
    }
  }, [logout])

  const handleViewTransactions = (transaction: Transaction) => {
    console.log(transaction)
    setSelectedTransaction(transaction)
    setDrawerOpen(true)
    // Fetch the transactions for the selected submerchant and store them in a state
  }
  const closeDrawer = () => {
    setDrawerOpen(false)
  }

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>TID</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Batch</TableCell>
              <TableCell>Terminal</TableCell>

              <TableCell>Actions</TableCell>
              <TableCell>View</TableCell>

              {/* Add more table headers as needed */}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.number}</TableCell>
                <TableCell>{row.amount}</TableCell>
                <TableCell>{row.t_type}</TableCell>
                <TableCell>{row.batch}</TableCell>
                <TableCell>{row.terminal_number}</TableCell>
                <TableCell>
                  <Link to={'/view-report/' + row.submerchant_id}>Generate Report {`>>>>>>>`}</Link>
                </TableCell>
                <TableCell>
                  <Button onClick={() => handleViewTransactions(row)}>View Submerchant</Button>
                </TableCell>
                {/* Add more table cells as needed */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Drawer anchor='right' open={isDrawerOpen} onClose={closeDrawer}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Submerchant ID</TableCell>
                <TableCell>MCC_Code</TableCell>
                <TableCell>Name</TableCell>
                {/* Add more table headers as needed */}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={1}>
                <TableCell>{selectedTransaction?.submerchant.id}</TableCell>

                <TableCell>{selectedTransaction?.submerchant.mcc}</TableCell>
                <TableCell>{selectedTransaction?.submerchant.name}</TableCell>
                {/* Add more table cells as needed */}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Drawer>
    </div>
  )
}

export default FintracReportTable
